////////////////////////////////////////
// SIDE NAVIGATION
.sidebar {
  background-color: var(--color-grey-dark-1);

  flex-direction: column;
  justify-content: space-between;
  max-width: 20rem;
  position: fixed;
  margin-top: 10rem;

  @media only screen and (max-width: $bp-small-medium) {
    display: none;
  }
}

.admin-sidebar {
  background-color: $blue;
  flex-direction: column;
  justify-content: space-between;
  max-width: 20rem;
  position: fixed;
  min-height: 100vh;

  @media only screen and (max-width: $bp-small-medium) {
    display: none;
  }
}

.side-nav {
  font-size: 1.4rem;
  list-style: none;
  margin: 5rem 0 5rem 2rem;

  /*@media only screen and (max-width: $bp-medium) {
    display: flex;
    margin: 0;
  }*/

  &__item:hover,
  &__item--active {
    color: grey;
  }

  &__link:link,
  &__link:visited {
    color: var(--color-grey-light-1);
    text-decoration: none;
    text-transform: uppercase;
    display: block;
    padding: 0.5rem 3rem;
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    /*@media only screen and (max-width: $bp-medium) {
            justify-content: center;
            padding: 2rem;
        }

        @media only screen and (max-width: $bp-small) {
            flex-direction: column;
            padding: 1.5rem .5rem;
        }*/
  }

  &__icon {
    width: 1.75rem;
    height: 1.75rem;
    margin-right: 2rem;
    fill: currentColor;

    /*@media only screen and (max-width: $bp-small) {
            margin-right: 0;
            margin-bottom: .7rem;
            width: 1.5rem;
            height: 1.5rem;
        }*/
  }
}

////////////////////////////////////////
// LEGAL TEXT
.legal {
  font-size: 1.2rem;
  color: var(--color-grey-light-4);
  text-align: center;
  padding: 2.5rem;

  @media only screen and (max-width: $bp-medium) {
    display: none;
  }
}

.nav {
  text-align: center;
  margin-bottom: 60px;
}

.nav-button {
  transition-delay: 0.05s;
  width: 50px;
  margin-right: 10px;
  padding: 10px;
}

.nav-button:hover {
  background: white;
  cursor: pointer;
}
