.content {
  display: flex;

  /*@media only screen and (max-width: $bp-medium) {
    flex-direction: column;
  }*/
}

.content-view {
  //display: flex;
  //flex-wrap: wrap;
  //justify-content: center;
  overflow: hidden;
  transition-delay: 0.05s;
  //padding: 5rem;
  padding: 10rem 5rem 5rem 27rem;
  width: 100%;

  @media only screen and (max-width: $bp-medium) {
  }

  @media only screen and (max-width: $bp-small-medium) {
    margin: 6rem 0 4rem 0;
    padding: 1rem;
  }
}

.admin-content-view {
  overflow: hidden;
  transition-delay: 0.05s;
  margin: 3rem 3rem 2rem 20rem;

  padding: 2rem;

  @media only screen and (max-width: $bp-small-medium) {
    margin: 6rem 0 4rem 0;
    padding: 1rem;
  }
}

.exhibition-image {
  padding: 3rem;

  &__item {
    margin: 3rem 0;
    width: 100%;
    box-shadow: 5px 5px 5px grey;
  }

  &__container {
    padding: 5rem;
  }
}

.thumbnail {
}

.thumbnail-image {
  transition: transform 0.5s ease;
  box-shadow: 5px 5px 5px grey;
  margin: auto;
  display: block;
}

.thumbnail-image:hover {
  filter: brightness(110%);
  cursor: pointer;
}

.thumbnail-text {
  padding: 3px 6px;
  text-align: center;
  color: black;
  font-size: 1.5rem;
}

a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
}

.socialMedia {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.socialMedia-item:not(:last-child) {
  margin-right: 30px;
}

.socialMedia-item {
  font-size: 1.5rem;
}

.socialMedia-item:hover {
  cursor: pointer;
}

.socialMedia-item--link {
  color: black;
  text-decoration: none;
  transition-delay: 0.05s;
}

.socialMedia-item--link:hover {
  color: grey;
}
