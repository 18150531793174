////////////////////////////////////////
// SIDE NAVIGATION
.admin-sidebar {
  //background-color: var(--color-grey-dark-1);
  //background-color: grey;
  //border: 2px solid black;
  //min-height: 100vh;
  //flex: 0 0 18%;

  //display: flex;
  //flex-direction: column;
  //justify-content: space-between;
}

.admin-side-nav {
  font-size: 1.4rem;
  list-style: none;
  margin-top: 3.5rem;

  /*@media only screen and (max-width: $bp-medium) {
    display: flex;
    margin: 0;
  }*/

  &__click {
    background-color: dimgrey;
  }

  &__item {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 0.5rem;

      /*@media only screen and (max-width: $bp-medium) {
        margin: 0;
      }*/
    }

    /*@media only screen and (max-width: $bp-medium) {
      flex: 1;
    }*/
  }

  &__item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 3px;
    background-color: var(--color-primary);
    transform: scaleY(0);
    transition: transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s,
      background-color 0.1s;
  }

  &__item:hover::before,
  &__item--active::before {
    transform: scaleY(1);
    width: 100%;
    background-color: dimgrey;
  }

  &__item:active::before {
    background-color: var(--color-primary-light);
  }

  &__link:link,
  &__link:visited {
    color: var(--color-grey-light-1);
    text-decoration: none;
    text-transform: uppercase;
    display: block;
    padding: 0.5rem 3rem;
    position: relative;
    z-index: 10;

    display: flex;
    align-items: center;

    /*@media only screen and (max-width: $bp-medium) {
      justify-content: center;
      padding: 2rem;
    }

    @media only screen and (max-width: $bp-small) {
      flex-direction: column;
      padding: 1.5rem .5rem;
    }*/
  }

  &__icon {
    width: 1.75rem;
    height: 1.75rem;
    margin-right: 2rem;
    fill: currentColor;

    /*@media only screen and (max-width: $bp-small) {
      margin-right: 0;
      margin-bottom: .7rem;
      width: 1.5rem;
      height: 1.5rem;
    }*/
  }
}

////////////////////////////////////////
// LEGAL TEXT
.legal {
  font-size: 1.2rem;
  color: var(--color-grey-light-4);
  text-align: center;
  padding: 2.5rem;

  /*@media only screen and (max-width: $bp-medium) {
    display: none;
  }*/
}

.nav {
  text-align: center;
  margin-bottom: 60px;
}

.nav-button {
  transition-delay: 0.05s;
  width: 50px;
  margin-right: 10px;
  padding: 10px;
}

.nav-button:hover {
  background: white;
  cursor: pointer;
}
