.hamburger-content {
  display: none !important;
  position: fixed;
  z-index: 10;
  &__open {
    z-index: 10 !important;
    height: 100vh;
    position: fixed;
    width: 100%;
    background-color: white;
  }

  @media only screen and (max-width: $bp-small-medium) {
    display: inline !important;
  }
}

.hamburger-menu {
  height: 100vh;
  position: fixed;
  width: 100%;
  top: 50%;
}

.hamburger-nav {
  list-style: none;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  &__item {
    z-index: 20;
    font-size: 3rem;
    text-transform: uppercase;

    &:not(:first-child) {
      margin-top: 5rem;
    }
  }

  &__item a {
    color: black;
  }
}
