.header {
  padding: 4rem;
  max-width: 24.11rem;
  position: fixed;
  white-space: initial;
  word-wrap: break-word;
  z-index: 10;

  @media only screen and (max-width: $bp-small-medium) {
    text-align: right;
    font-size: 2vw;
    padding: 1rem 1rem 0 4rem;
    max-width: initial;
    right: 0;
  }
}

.header-bar {
  display: none;

  @media only screen and (max-width: $bp-small-medium) {
    display: initial;
    width: 100%;
    height: 4rem;
    background-color: #fafafa;
    position: fixed;
    z-index: 5;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.75);
  }
}

.admin-header {
  padding: 2rem 4rem;
  max-width: 24.11rem;
  position: fixed;
  white-space: initial;
  word-wrap: break-word;
  z-index: 10;

  @media only screen and (max-width: $bp-small-medium) {
    text-align: right;
    font-size: 2vw;
    padding: 1rem 1rem 0 4rem;
    max-width: initial;
    right: 0;
  }
}
