.dialog-delete {
  background-color: white;
  border-radius: 5px;
  position: absolute;
  margin: auto;

  &__title-delete {
    color: red;
  }

  &__title-success {
    color: green;
  }

  &__icon {
    margin-left: 5px;
  }
}