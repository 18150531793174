.login-content {
  background-color: #fff;
  padding: 2rem;
  align-self: center;
  border-radius: 4px;
  box-shadow: 0px 1px 5px 0px;
  min-width: 25rem;
}

.login-input {
  display: flex;
  flex-direction: column;

  &__button {
    margin-top: 1rem !important;
    font-size: 1rem;
    position: relative;
  }

  &__loading {
    position: absolute;
  }

  &__item {
    font-size: 1rem;
  }

  &__error {
    font-size: 1.3rem !important;
    color: red;
    margin: 1rem 0 !important;
  }
}

.login-header {
  text-align: center;
  margin: 3rem 0px !important;
}