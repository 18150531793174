.contact {
  display: flex;
  //box-shadow: 5px 5px 5px black;
  max-width: 80rem;

  @media only screen and (max-width: $bp-small) {
    padding: 3rem;
  }

  &__form {
    @media only screen and (max-width: $bp-small) {
      margin-top: 2rem;
    }
  }

  &__button {
    margin: 2.2rem 0 !important;
  }
}

.contact-information {
  font-size: 2rem;
  padding: 3rem 3rem 0 3rem;
  height: 100%;

  &__image {
    width: 12rem !important;
    height: 12rem !important;
    margin-right: 1.5rem !important;
    max-width: 12rem !important;
    float: left !important;
    shape-outside: circle(50% at 50% 50%) !important;
    -webskit-shape-outside: circle(50% at 50% 50%) !important;
  }

  &__item:hover &__icon {
    filter: opacity(0.75);
  }

  @media only screen and (max-width: $bp-small) {
    padding: 0;
  }
}
