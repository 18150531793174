.container {
  min-height: 100vh;
  //background-image: url(../media/background.png);

  //max-width: 120rem;
  //padding-top: 10rem;
  //margin: 8rem auto;
}

.login-container {
  min-height: 100vh;
  background: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.admin-container {
  min-height: 100vh;
  background: $blue;
  /*display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;*/
}
