.create-exhibition {
  &__input {
    display: none;
  }

  &__image-cards {
    box-shadow: 5px 5px 5px grey;
    background-color: white;
    border-radius: 5px;
  }
}

.exhibition-form {
  padding: 2rem;
  border-radius: 5px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-self: center;
  box-shadow: 3px 3px 3px black;

  &__input {
    font-size: 4rem !important;
    color: red !important;
    border: 5px solid black;
  }
}
.exhibition-images {
  padding: 2rem;
  border-radius: 5px;
  background-color: white;
  box-shadow: 3px 3px 3px black;
}
.thumbnail {
  &__ {
  }
}

.upload-button {
  margin: 3rem 0 !important;

  &__icon {
    margin-left: 5px;
    font-size: 1.3rem;
  }
}

.add-button {
  &__icon {
    margin-left: 5px;
    font-size: 2.2rem !important;
  }
}

.exhibition-image {
  object-fit: scale-down;

  &__selected {
    border: 3px solid orange;
  }

  &__button {
    cursor: not-allowed !important;
  }

  &__button:hover {
    cursor: not-allowed !important;
  }
}

.exhibition-images:not(:last-child) {
  margin-left: 1rem;
}

.image-card {
  width: 16rem;
}
